import React from 'react';
import { Container, Typography, TextField, Button, Box } from '@mui/material';
import '../Contact.css';

const Contact = () => (
  
  <Box id="about-us" sx={{
    padding: '10px 10px',
    background: '#a01414', // Fading into transparent
    color: '#FFFFFF'
  }}>
  <Container
    id="contact"
    sx={{
      py: 8,
   
    }}
  >
    <Typography color="#fff" variant="h4" align="center" gutterBottom margin={5}>
      Contact Us
    </Typography>
    <Box component="form" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <TextField label="Name" variant="outlined" sx={{ mb: 3, width: '80%' }} />
      <TextField label="Email" variant="outlined" sx={{ mb: 3, width: '80%' }} />
      <TextField label="Message" multiline rows={4} variant="outlined" sx={{ mb: 3, width: '80%' }} />
      <Button variant="contained" sx={{ backgroundColor: '#800000' }}>Send Message</Button>
    </Box>
  </Container>
  </Box>
);

export default Contact;
