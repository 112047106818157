import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
  Dialog,
  DialogContent,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import PhoneIcon from '@mui/icons-material/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setDrawerOpen(false);
  };

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <>
      {/* Red Bar with Social Media Icons and Phone Number */}
      <Box
        sx={{
          backgroundColor: '#a01414', // Red background color
          color: '#ffffff',
          padding: '5px 0',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'sticky', // Make it sticky
          top: 0, // Stick to the top
          zIndex: 1000, // Ensure it stays above the navbar
        }}
      >
        {/* Phone Number */}
        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
          <PhoneIcon sx={{ color: '#ffffff', marginRight: '5px' }} />
          <Typography variant="body2">+256 123 456 789</Typography>
        </Box>

        {/* Social Media Icons */}
        <Box sx={{ display: 'flex', gap: 2, marginRight: '10px' }}>
          <IconButton color="inherit" href="https://facebook.com" target="_blank">
            <FacebookIcon sx={{ color: '#ffffff' }} />
          </IconButton>
          <IconButton color="inherit" href="https://instagram.com" target="_blank">
            <InstagramIcon sx={{ color: '#ffffff' }} />
          </IconButton>
          <IconButton color="inherit" href="https://twitter.com" target="_blank">
            <TwitterIcon sx={{ color: '#ffffff' }} />
          </IconButton>
          <IconButton color="inherit" href="https://linkedin.com" target="_blank">
            <LinkedInIcon sx={{ color: '#ffffff' }} />
          </IconButton>
        </Box>
      </Box>

      {/* Navbar */}
      <AppBar
        position="sticky" // Make navbar sticky
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.9)', // Semi-transparent black background
          color: '#ffffff',
          boxShadow: 'none',
          height: '64px',
          top: '50px', // Adjust to be just below the red bar
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* Logo and Title Section */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/logo.png`}
              alt="Logo"
              style={{ height: '40px', marginRight: '10px' }}
            />
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Am Raven Visual Sound Events
            </Typography>
          </Box>

          {/* Navigation Links */}
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              justifyContent: 'center', // Center menu items on large screens
              flexGrow: 1, // Ensure the Box takes up available space
              gap: 3,
            }}
          >
            {['Home', 'Services', 'Events', 'Contact'].map((text) => (
              <Button
                key={text}
                onClick={() => handleScroll(text.toLowerCase())}
                sx={{
                  color: '#ffffff', // White text
                  textTransform: 'capitalize',
                  fontWeight: 'bold',
                  '&:hover': { color: '#FF5733' }, // Highlight color on hover
                }}
              >
                {text}
              </Button>
            ))}
          </Box>

          {/* Get In Touch Button for Desktop */}
          <Button
            variant="contained"
            onClick={openModal}
            sx={{
              background: '#a01414', // Gradient
              color: '#ffffff',
              textTransform: 'capitalize',
              fontWeight: 'bold',
              '&:hover': { background: 'linear-gradient(to right, #C70039, #FF5733)' },
              marginLeft: 'auto', // Push the button to the right
              display: { xs: 'none', sm: 'block' }, // Hide on small screens
            }}
          >
            Get In Touch
          </Button>

          {/* Menu Icon for Mobile */}
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ display: { xs: 'block', sm: 'none' } }}
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer for Mobile */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        PaperProps={{
          sx: {
            backgroundColor: '#000000', // Black background
            color: '#ffffff',
            width: '250px',
          },
        }}
      >
        <List sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: 1 }}>
          {['Home', 'Services', 'Events', 'Contact'].map((text) => (
            <ListItem
              button
              key={text}
              onClick={() => handleScroll(text.toLowerCase())}
              sx={{
                color: '#ffffff', // White text color
                textAlign: 'center',
                '&:hover': {
                  backgroundColor: '#333333', // Slightly lighter black on hover
                },
              }}
            >
              <ListItemText
                primary={text}
                sx={{
                  textAlign: 'center',
                  color: 'inherit', // Inherit the white text color
                  fontWeight: 'bold',
                }}
              />
            </ListItem>
          ))}
          {/* Get In Touch Button for Mobile */}
          <ListItem sx={{ justifyContent: 'center' }}>
            <Button
              variant="contained"
              sx={{
                width: '80%',
                background: '#a01414', // Gradient
                color: '#ffffff',
                textTransform: 'capitalize',
                fontWeight: 'bold',
                '&:hover': { background: 'linear-gradient(to right, #C70039, #FF5733)' },
              }}
              onClick={openModal}
            >
              Get In Touch
            </Button>
          </ListItem>
        </List>
      </Drawer>

      {/* Modal for Get In Touch */}
      <Dialog
        open={modalOpen}
        onClose={closeModal}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            background: 'linear-gradient(to bottom, #000000, #333333)', // Black gradient modal
            color: '#ffffff',
            padding: 3,
          },
        }}
      >
        <DialogContent sx={{ textAlign: 'center', position: 'relative' }}>
          {/* Close Button */}
          <IconButton
            onClick={closeModal}
            sx={{
              position: 'absolute',
              top: 16,
              right: 16,
              color: '#FF5733',
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Modal Content */}
          <Typography variant="h5" sx={{ marginBottom: 2, color: '#FF5733', fontWeight: 'bold' }}>
            Contact Us
          </Typography>
          <Typography variant="body1">
            Email us at{' '}
            <a href="mailto:info@example.com" style={{ color: '#FF5733' }}>
              info@ravenevents.com
            </a>{' '}
            <br />
            Call us: +256 777 045 758
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Navbar;
