import React from "react";
import { Box, Typography, Button } from "@mui/material";

const Hero = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        position: "relative",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        padding: 2,
        overflow: "hidden",
        zIndex: 1,
      }}
    >
      {/* Background Slider */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/event1.jpeg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          animation: "zoomIn 15s infinite, fadeBackground 15s infinite",
          opacity: 0.7,
          zIndex: 1,
        }}
      ></Box>

      {/* Text and Description with Different Animations for Each Image */}
      <Typography
        variant="h2"
        sx={{
          fontWeight: "bold",
          mb: 2,
          animation: "text3DColumnLeft 3s ease-in-out, textDistortion 3s ease-in-out",
        }}
      >
      <Typography
  sx={{
    backgroundColor: "#a01414",  // Golden yellow background
    color: "white",  // Black text color
    padding: "12px 30px",  // Padding for spacing
    borderRadius: "25px",  // Rounded corners
    fontWeight: "bold",  // Bold text
    textAlign: "center",  // Centered text
    display: "inline-block",  // Make it inline-block like a button
    "&:hover": {
      backgroundColor: "#cba631",  // Darker shade of yellow on hover
      cursor: "pointer",  // Change cursor to pointer on hover
    },
    zIndex: 1,  // Ensure it's above other content
    transition: "background-color 0.3s ease",  // Smooth background-color transition
  }}
>
  Am Raven Events
</Typography>
        
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mb: 3,
          fontWeight: "bold",
          animation: "text3DColumnLeft 3s ease-in-out 1s, textDistortion 3s ease-in-out 1s", // Delayed animation for second image
        }}
      >
       
       
      </Typography>
      {/* <Button
        variant="contained"
        sx={{
          backgroundColor: "#d4af37",
          color: "#000",
          "&:hover": { backgroundColor: "#cba631" },
          zIndex: 1,
        }}
      >
        Learn More
      </Button> */}

      {/* CSS for Background and Text Animations */}
      <style>
        {`
          /* Background Zoom-In Effect */
          @keyframes zoomIn {
            0% {
              transform: scale(1.5);
            }
            100% {
              transform: scale(1);
            }
          }

          /* Background Image Transition */
          @keyframes fadeBackground {
            0% {
              background-image: url('${process.env.PUBLIC_URL}/assets/event1.jpeg');
              
            }
            33% {
              background-image: url('${process.env.PUBLIC_URL}/assets/event2.JPG');
              
            }
            50% {
              background-image: url('${process.env.PUBLIC_URL}/assets/event3.JPG');
             
            }
            60% {
              background-image: url('${process.env.PUBLIC_URL}/assets/event3.JPG');
              
            }
            70% {
              background-image: url('${process.env.PUBLIC_URL}/assets/event1.jpeg');
              
            }
            80% {
              background-image: url('${process.env.PUBLIC_URL}/assets/event2.JPG');
              
            }
            78% {
              background-image: url('${process.env.PUBLIC_URL}/assets/event3.JPG');
              
            }
          }

          /* 3D Column Left Animation for Text */
          @keyframes text3DColumnLeft {
            0% {
              opacity: 0;
              transform: translateX(-100%) rotateY(90deg);
            }
            50% {
              opacity: 1;
              transform: translateX(0) rotateY(0deg);
            }
            100% {
              opacity: 1;
              transform: translateX(0) rotateY(0deg);
            }
          }

          /* Distortion Animation for Text */
          @keyframes textDistortion {
            0% {
              opacity: 0;
              transform: scale(1) skewX(30deg);
            }
            50% {
              opacity: 1;
              transform: scale(1.1) skewX(0deg);
            }
            100% {
              opacity: 1;
              transform: scale(1) skewX(0deg);
            }
          }
        `}
      </style>
    </Box>
  );
};

export default Hero;
