import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#800000', // Maroon
    },
    background: {
      default: '#ffffff', // White
    },
    text: {
      primary: '#800000', // Maroon text color
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
  },
});

export default theme;
