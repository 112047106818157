import React from 'react';
import { Grid, Box, Typography, IconButton } from '@mui/material';
import { CheckCircle } from '@mui/icons-material'; // Use check icon for visual appeal

const reasons = [
  {
    title: 'Tailored Experiences',
    description: 'We tailor each event to meet your unique needs and vision.',
    icon: <CheckCircle sx={{ fontSize: 40, color: '#d4af37' }} />
  },
  {
    title: 'Professional Team',
    description: 'Our team is composed of experienced professionals who deliver excellence.',
    icon: <CheckCircle sx={{ fontSize: 40, color: '#d4af37' }} />
  },
  {
    title: 'High-Quality Equipment',
    description: 'We use the best equipment to ensure a flawless event experience.',
    icon: <CheckCircle sx={{ fontSize: 40, color: '#d4af37' }} />
  },
  {
    title: 'Customer-Centric',
    description: 'Our focus is always on you, ensuring your event is on Point.',
    icon: <CheckCircle sx={{ fontSize: 40, color: '#d4af37' }} />
  },
  {
    title: 'Innovative Solutions',
    description: 'We bring fresh, creative ideas to make your event stand out.',
    icon: <CheckCircle sx={{ fontSize: 40, color: '#d4af37' }} />
  },
  {
    title: 'Comprehensive Packages',
    description: 'We offer all-in-one packages to simplify event planning.',
    icon: <CheckCircle sx={{ fontSize: 40, color: '#d4af37' }} />
  }
];

const Services = () => {
  return (
    <Box id="why-us" sx={{ padding: '60px 20px', backgroundColor: '#1a1a1a', color: '#fff' }}>
      {/* Main Heading */}
      <Typography variant="h4" align="center" sx={{ fontWeight: 'bold', mb: 4, color: '#d4af37' }}>
        Why Choose Us
      </Typography>

      {/* Subheading */}
      <Typography variant="h5" align="center" sx={{ mb: 6, color: '#fff' }}>
        Tailoring unforgettable experiences for every occasion, while embracing the Unique Essence of each Event and Client.
      </Typography>

      {/* Reasons Grid */}
      <Grid container spacing={3} justifyContent="center">
        {reasons.map((reason, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              sx={{
                backgroundColor: '#2e2e2e',
                padding: '20px',
                borderRadius: 2,
                textAlign: 'center',
                boxShadow: 3,
                '&:hover': {
                  transform: 'scale(1.05)', // Hover effect for interactivity
                  boxShadow: 6, // Enhance shadow on hover
                },
                transition: 'transform 0.3s ease-in-out',
              }}
            >
              <Box sx={{ mb: 2 }}>
                {reason.icon} {/* Icon for each reason */}
              </Box>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, color: '#d4af37' }}>
                {reason.title}
              </Typography>
              <Typography variant="body2" sx={{ color: '#fff' }}>
                {reason.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Services;
