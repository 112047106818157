import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
//import Header from './components/Header';
import Hero from './components/Hero';
import Services from './components/Services';
import Navbar from './components/Navbar';
import Events from './components/Events';
import Contact from './components/Contact';

import Footer from './components/Footer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// import ThreeDRaven from './components/ThreeDRaven'; // Adjust the path as needed
import './App.css'; // Import App.css here

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
      <Navbar />
      <Hero />
      <Services />
      <Events />
      <Contact />     
      <Footer />
    </div>
      
    </ThemeProvider>
  );
}


export default App;
