import React from 'react';
import Slider from 'react-slick';
import { Box, Typography, Grid, IconButton, Link } from '@mui/material';
import { Email, Phone, LocationOn } from '@mui/icons-material';
import { Facebook, Instagram, WhatsApp } from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Footer = () => {
  // Custom Arrow Components
  const ArrowBack = (props) => (
    <IconButton
      {...props}
      sx={{
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 2,
        color: '#FFD700',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        '&:hover': {
          backgroundColor: 'rgba(255, 215, 0, 0.8)',
        },
      }}
    >
      <ArrowBackIosIcon />
    </IconButton>
  );

  const ArrowForward = (props) => (
    <IconButton
      {...props}
      sx={{
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 2,
        color: '#FFD700',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        '&:hover': {
          backgroundColor: 'rgba(255, 215, 0, 0.8)',
        },
      }}
    >
      <ArrowForwardIosIcon />
    </IconButton>
  );

  // Slider settings
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <ArrowForward />,
    prevArrow: <ArrowBack />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        backgroundColor: '#000',
        color: '#fff',
        padding: '40px 20px',
        overflowX: 'hidden',
      }}
    >
     {/* Image Slider Section */}
     <Box
        sx={{
          marginTop: 2,
          position: 'relative',
          overflow: 'hidden',
          marginBottom: 3,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            position: 'absolute',
            right: 0,
            top: -20,
            fontWeight: 'bold',
            color: '#FFD700',
            writingMode: 'vertical-rl',
            transform: 'rotate(180deg)',
          }}
        >
          Gallery
        </Typography>
        <Slider {...sliderSettings}>
          {[
            '/assets/event1.jpeg',
            '/assets/event2.jpg',
            '/assets/event3.jpg',
            '/assets/event1.jpeg',
            '/assets/event2.jpg',
            '/assets/event3.jpg',
            '/assets/event1.jpeg',
          ].map((image, index) => (
            <Box key={index} sx={{ padding: '0 8px' }}>
              <img
                src={image}
                alt={`Event ${index + 1}`}
                style={{
                  width: '100%',
                  height: '200px',
                  objectFit: 'cover',
                  borderRadius: '8px',
                }}
              />
            </Box>
          ))}
        </Slider>
      </Box>
      <Grid container spacing={4}>
        {/* About Section */}
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#FFD700' }}>
            Am Raven Events
          </Typography>
          <Typography variant="body2" sx={{ marginTop: 1 }}>
          At <strong> Am Raven Events</strong> is a diversified company
          dedicated to delivering excellence.
          </Typography>
        </Grid>

        {/* Quick Links Section */}
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#FFD700' }}>
            Useful Links
          </Typography>
          <Box sx={{ marginTop: 1 }}>
            <Link href="#" color="inherit" underline="hover" display="block">
              Home
            </Link>
            <Link href="#" color="inherit" underline="hover" display="block">
              Services
            </Link>
            <Link href="#" color="inherit" underline="hover" display="block">
              Contact
            </Link>
            <Link href="#" color="inherit" underline="hover" display="block">
              About Us
            </Link>
          </Box>
        </Grid>

        {/* Contact Info Section */}
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#FFD700' }}>
            Contact Info
          </Typography>
          <Box sx={{ marginTop: 1 }}>
            <Typography variant="body2" display="flex" alignItems="center" sx={{ marginBottom: 1 }}>
              <LocationOn sx={{ marginRight: 1 }} />
             Nabweru
            </Typography>
            <Typography variant="body2" display="flex" alignItems="center" sx={{ marginBottom: 1 }}>
              <Email sx={{ marginRight: 1 }} />
              info@amravenevents.com
            </Typography>
            <Typography variant="body2" display="flex" alignItems="center">
              <Phone sx={{ marginRight: 1 }} />
              +256 123 456 789
            </Typography>
            <Typography variant="body2" display="flex" alignItems="center">
              <Phone sx={{ marginRight: 1 }} />
              +256 759 010 024
            </Typography>
            
          </Box>
        </Grid>
      </Grid>

     

      {/* Social Media Icons */}
      <Box sx={{ textAlign: 'center', marginTop: 4 }}>
        <IconButton href="https://facebook.com" sx={{ color: '#fff' }}>
          <Facebook />
        </IconButton>
        <IconButton href="https://instagram.com" sx={{ color: '#fff' }}>
          <Instagram />
        </IconButton>
        <IconButton href="https://wa.me/YOUR_NUMBER" sx={{ color: '#fff' }}>
          <WhatsApp />
        </IconButton>
      </Box>
      <Typography variant="body2" sx={{ marginTop: 1, textAlign:'center' }}>
            Designed By Kitumba Derrick | +256 759 010 024
          </Typography>
    </Box>
  );
};

export default Footer;
